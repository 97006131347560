import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

export default function Restaurant() {
  return (
    <Layout>
      <Seo title="Restaurant" />
      <div className="container py-8">
        <button
          className="px-4 py-2 rounded shadow text-white"
          style={{ backgroundColor: "#00BFFF" }}
        >
          This is a primary button.
        </button>

        <h1 className="h1">
          Professional Website for Your Restaurant Custom Designed by Thomas'
          Webdesign
        </h1>
        <p className="paragraph">
          As a restaurant owner, you know the importance of providing a great
          dining experience for your customers. But in today's digital age, a
          great website is just as important for attracting and retaining
          customers.
        </p>
        <p className="paragraph">
          At Thomas Webdesign, we specialize in creating stunning and effective
          websites for restaurants. Our team of experienced designers and
          developers can create a custom website that perfectly reflects your
          restaurant's unique style and menu. We offer a range of services,
          including:
        </p>
        <p className="paragraph">
          <ul className="list-inside list-disc">
            <li>Custom website design and development</li>
            <li>Responsive and mobile-friendly design</li>
            <li>Integration with online ordering and reservation systems</li>
            <li>
              Menu and photo galleries to showcase your dishes and atmosphere
            </li>
            <li>
              Search engine optimization to help customers find your restaurant
            </li>
            <li>
              Ongoing support and maintenance to keep your website running
              smoothly
            </li>
          </ul>
        </p>
        <p className="paragraph">
          We understand the importance of a fast-loading and visually appealing
          website, so our websites are optimized for speed and aesthetics. And
          with our ongoing support and maintenance services, you can trust that
          your restaurant's online presence is in good hands.
        </p>
        <p className="paragraph">
          A well-designed website allows customers to easily learn about your
          restaurant, view your menu, and make reservations or place orders
          online. It also provides a platform for showcasing your unique
          atmosphere and special offers. But a website for your restaurant
          offers even more benefits. It can help you reach a wider audience and
          attract new customers who may not have heard of your restaurant
          through traditional marketing channels. A website can also help you
          build a stronger brand and establish your restaurant as a reputable
          and trustworthy business.
        </p>
        <p className="paragraph">
          A website can also improve the efficiency of your operations. Online
          ordering and reservation systems can reduce the workload on your staff
          and improve the accuracy of orders and reservations. And with the
          right website design and development team, your website can be
          optimized for mobile devices, allowing customers to easily access your
          restaurant's information on the go.
        </p>
        <p className="paragraph">
          Overall, a website is a valuable asset for any restaurant. It can help
          you attract and retain customers, build your brand, and improve your
          operations. Investing in a well-designed and user-friendly website is
          a smart decision for any restaurant owner.
        </p>
        <p className="paragraph">
          Contact Thomas Webdesign today to have a professional website custom
          designed for your restaurant. Our pricing is competitive and we offer
          flexible payment options to suit your budget. Don't miss out on the
          many benefits of a great website for your restaurant –{" "}
          <strong>contact us today to get started</strong>.
        </p>
      </div>
    </Layout>
  )
}
